<template>
  <div>
    <div class="arr_form">
      <!--   自定义区域   -->
      <div>
        <slot name="customButton" />
      </div>
      <!--  搜索 筛选 选择 导出  -->
      <div>
        <a-input-search
          v-if="toList.includes('search')"
          v-model.trim="conditionList.searchValue"
          :placeholder="searchPlaceholder"
          :style="[searchStyle]"
          @search="getSearchCallback"
        >
          <template slot="suffix">
            <svg class="iconpark-icon" @click="getSearchCallback"><use href="#search" /></svg>
          </template>
        </a-input-search>
        <!-- <a-popover>
          <template slot="content">
            <p class="popover_p">{{ searchPlaceholder }}</p>
          </template>
          <a-input-search
            v-if="toList.includes('search') && showPopover"
            v-model.trim="conditionList.searchValue"
            :placeholder="searchPlaceholder"
            style="width: 200px;"
            @search="getSearchCallback"
          />
        </a-popover> -->
        <a-tooltip placement="top">
          <template slot="title">
            <span>筛选</span>
          </template>
          <a-button
            v-if="toList.includes('screen')"
            type="iconBtn"
            icon="shaixuan"
            class="menuSearch_icon"
            @click="getButtonCallback('screen')"
          />
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>导出</span>
          </template>
          <a-button
            v-if="toList.includes('export')"
            type="iconBtn"
            icon="daochu"
            class="menuSearch_icon"
            @click="getButtonCallback('export')"
          />
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>表头设置</span>
          </template>
          <a-button
            v-if="toList.includes('choice')"
            type="iconBtn"
            icon="biaotoushezhi"
            class="menuSearch_icon"
            @click="getButtonCallback('choice')"
          />
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>刷新</span>
          </template>
          <a-button
            v-if="toList.includes('refresh')"
            type="iconBtn"
            class="menuSearch_icon"
            anticon="sync"
            @click="getButtonCallback('refresh')"
          />
        </a-tooltip>
        <slot name="rightButton" />
      </div>
    </div>
    <div>
      <!-- 筛选条件： -->
      <!-- <span v-for="(key, index) in searchValue">

      </span> -->
    </div>
    <!--  筛选  -->
    <ScreenRoster ref="ScreenRoster" @screenRosterParams="screenRosterParams" />
    <!--  位置选择  -->
    <InfoSearch ref="InfoSearch" @infoSearchCallback="infoSearchCallback" />
  </div>
</template>

<script>
export default {
  name: 'AppForm',
  components: {
    ScreenRoster: () => import('./operation/screenRoster'),
    InfoSearch: () => import('./operation/infoSearch')
  },
  props: {
    toList: {
      type: Array,
      default: () => []
    },
    displayList: {
      type: Array,
      default: () => []
    },
    notShownList: {
      type: Array,
      default: () => []
    },
    doNotDragList: {
      type: Array,
      default: () => []
    },
    screenRosterList: {
      type: Object,
      default: () => {}
    },
    searchPlaceholder: {
      type: String,
      default: '搜索员工'
    },
    // width: {
    //   type: [String, Number],
    //   default: 380
    // },
    searchStyle: {
      type: Object,
      default: () => { return { width: '200px' } }
    }
  },
  data() {
    return {
      conditionList: {
        searchValue: '',
        screenCondition: {}
      },
      displayArr: {}
    }
  },
  methods: {
    // 按钮回调
    getButtonCallback(type) {
      if (type === 'screen') {
        this.$refs.ScreenRoster.show(this.screenRosterList, this.width)
      } else if (type === 'choice') {
        const params = {
          notShownList: this.notShownList,
          displayList: this.displayList,
          doNotDragList: this.doNotDragList
        }
        this.$refs.InfoSearch.show(params)
      } else if (type === 'export') {
        this.$emit('exportFileList')
      } else if (type === 'refresh') {
        this.$emit('refreshTableList')
      }
    },
    // 搜索回调
    getSearchCallback() {
      this.$emit('getSearchCallback', this.conditionList)
    },
    // 筛选
    screenRosterParams(params) {
      this.conditionList.screenCondition = params
      // this.paramsDataFormate(params)
      this.$emit('screenRosterParams', this.conditionList)
    },
    // 刷新
    refresh() {
      this.conditionList.searchValue = undefined
      this.$refs.ScreenRoster.reset()
    },
    // 格式化数据，拼接出来筛选框已经选择的条件
    // paramsDataFormate(params) {
    //   const list = []
    //   this.screenRosterList.list.forEach(item => {
    //     list.push({
    //       label: item.label,
    //       value: params[item.model]
    //     })
    //   })
    //   console.log('xxxxxxxxxxx', list)
    // },
    // 选择表头
    infoSearchCallback(data, val) {
      this.$emit('searchHeaderCallback', data, val)
    }
  }
}
</script>

<style lang="less" scoped>
  .arr_form {
    display: flex;
    justify-content: space-between;
    margin:0 0 16px 0;
    /deep/.ant-input-search{
      vertical-align: middle;
       .ant-input{
        border-color: @sc-greyBg-20;
      }
      .ant-input-suffix{
        .iconpark-icon{
          color: @sc-grey-60;
        }
      }
      .ant-input-search-icon{
        display: none;
      }
    }
    .iconpark-icon{
      width: 16px;
      height: 16px;
    }
    .menuSearch_icon {
      margin-left: 12px;
      font-size: 14px;
      font-weight: normal;
      color: @sc-grey-100;
      &:hover{
        color: @sc-primary-100;
        border-color: @sc-primary-100;
      }
    }
  }
  .popover_p{
    margin: 0;
  }
</style>
